import React, {Component} from 'react';
import ReactHighcharts from 'react-highcharts';
import addNoDataModule from 'highcharts/modules/no-data-to-display';
import highchartsExporting from 'highcharts/modules/exporting';
import moment from 'moment';
import SensorUtils from '../../Dashboard/Sensor/SensorUtils';
import {Button} from 'antd';
import {Trans} from 'react-i18next';
import ChartLegend from './ChartLegend';
import {
	ALARM_EVENTS,
	FLIGHT_MODE_EVENTS,
	LOGGER_STATUS_EVENTS,
	MENU_SET_MARK_EVENTS,
	OCCURRENCES,
	OTHER_OCCURRENCES,
	PLOT_LINE_COLORS,
	REPLACE_SENSOR_EVENTS,
	RUN_EVENTS,
	SENSOR_ISSUE_ALARMS,
	SENSOR_LIMIT_ALARMS,
} from '../../Shared/Constants/Chart';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import GroupAlarmStatusesAccordingToDate from './ExtensionMethods/GroupAlarmStatusesAccordingToDate';
import PickColorOfPlotLine from './ExtensionMethods/PickColorOfPlotLine';
import ChartConfiguration from './ChartConfiguration';
import PrepareDescText from './ExtensionMethods/PrepareDescText';
import {highchartCustomPlotbands} from './ExtensionMethods/HighChartPlotbandExtension';
import {ChartComponentProps} from './ChartComponentProps';
import {ChartComponentState} from './ChartComponentState';
import {DeviceFamily} from '../../../common/constants';

class ChartComponent extends Component<ChartComponentProps, ChartComponentState> {
	chart: any;
	series: any[];
	alarmZones: any[];
	sensorReplaces: any[];
	legendUpdateFun: any;
	startStopRuns: any[];
	alarmingOnOff: any[];
	menuAlarmingOnOffOccurrences: any[];
	flightModeOnOff: any[];
	flightModeOnOffOccurrences: any[];
	logStatuses: any[];
	occurrences: any[];
	sensorLimitAlarms: any[];
	sensorErrorValues: any[];
	sensorIssueAlarms: any[];
	MenuSetMark: any[];
	menuSetMarkOccurrences: any[];
	deferredDataPoints: any[];
	all: any[];
	communication_times: any[];
	bypassedDataPoints: any[];
	sensorCalibrations: any[];

	constructor(props: ChartComponentProps) {
		super(props);
		addNoDataModule(ReactHighcharts.Highcharts);
		highchartsExporting(ReactHighcharts.Highcharts);
		highchartCustomPlotbands(ReactHighcharts.Highcharts);

		this.state = {
			chart: undefined,
			showReloadedValues: false,
			showSensorCalibrationValues: false,
			showBypassedValues: true, //Alarming inactive
			showInTransit: false,
			showMeasurementUploads: false, //Communication times
			startRunStopRunSettings: {
				showStartRunStopRunCheckBox: false,
				isEnabled: false,
				legends: [],
				showStartRunLegend: false,
				showStopRunLegend: false,
			},
			alarmingOnAlarmingOffSettings: {
				showAlarmingOnAlarmingOffCheckBox: false,
				isEnabled: false,
				legends: [],
				showAlarmingOnLegend: false,
				showAlarmingOffLegend: false,
			},
			logStatusesSettings: {
				showLogStatusesCheckBox: false,
				isEnabled: false,
				legends: [],
				showStartLegend: false,
				showLogDelayedLegend: false,
				showLogTransitLegend: false,
				showLogPausedLegend: false,
				showLogArrivedLegend: false,
				showLogStoppedLegend: false,
				showCalibLegend: false,
			},
			sensorLimitAlarmsSettings: {
				showSensorLimitAlarmsCheckBox: false,
				isEnabled: false,
				legends: [],
				showSensorUpperLimitAlarmLegend: false,
				showSensorLowerLimitAlarmLegend: false,
			},
			sensorIssueAlarmsSettings: {
				showSensorIssueAlarmsCheckBox: false,
				isEnabled: false,
				legends: [],
				showRadioConnectionWarningLegend: false,
				showLostMeasurementAlarmLegend: false,
				showLowBatteryWarningLegend: false,
				showMissingCommunicationWarningLegend: false,
				showMissingValueAlarmLegend: false,
				showSensorFailureAlarmLegend: false,
			},
			flightModeOnOffSettings: {
				showFlightModeOnOffCheckBox: false,
				isEnabled: false,
				legends: [],
				showFlightModeOnLegend: false,
				showFlightModeOffLegend: false,
			},
			menuSetMarkSettings: {
				showMenuSetMarkCheckBox: false,
				isEnabled: false,
				legends: [],
				showMenuSetMarkLegend: false,
			},
			sensorReplacesSettings: {
				showSensorReplacesCheckBox: false,
				isEnabled: false,
				legends: [],
				showSensorReplacesLegend: false,
			},
		};
	}

	componentDidMount() {
		this.reloadChart();
	}

	componentDidUpdate(prevProps) {
		if (this.props.measurements !== prevProps.measurements || this.props.limitAlarms !== prevProps.limitAlarms) {
			this.reloadChart();
		}

		if (this.props.sensorLimitAlarms !== prevProps.sensorLimitAlarms) {
			this.DrawSensorLimitAlarms();
		}

		if (this.props.sensorIssueAlarms !== prevProps.sensorIssueAlarms) {
			this.DrawSensorIssueAlarms();
		}

		if (this.props.sensorReplaces !== prevProps.sensorReplaces) {
			this.DrawSensorReplaces();
		}
	}

	reloadChart = () => {
		this.series = [];
		this.alarmZones = [];
		this.sensorReplaces = [];
		this.series.push({
			id: 'main',
			name: this.props.sensor ? this.props.sensor.name : 'sensor not found!',
			data: [],
		});
		this.series.push({
			id: 'events',
			data: [],
			showInLegend: false,
			visible: false,
		});

		if (this.props.measurements && this.props.measurements.length > 0) {
			this.drawGraphic();
			this.drawAlarmLimits();

			if (this.props.moduleFamilyType !== undefined && this.props.moduleFamilyType !== null) {
				switch (this.props.moduleFamilyType) {
					case DeviceFamily.LiberoGx: {
						this.DrawStartRunStopRun();
						this.DrawAlarmingOnAlarmingOff();
						this.DrawFlightModeOnOff();
						this.DrawLoggerStatuses();
						this.DrawMenuSetMark();
						this.DrawSensorLimitAlarms();
						break;
					}
					case DeviceFamily.EcologProXG:
						this.DrawFlightModeOnOff();
						this.DrawSensorIssueAlarms();
						this.DrawSensorReplaces();
						this.DrawMenuSetMark();
						break;
					case DeviceFamily.EcologProRadio: {
						this.DrawSensorIssueAlarms();
						this.DrawSensorReplaces();
						break;
					}
					default:
					//do nothing.
				}
			}
		}
	};

	setPlotLinesVisible(container, xAxis, plotLineColorAndId) {
		for (const item of container) {
			let text = '';
			let m = moment(item.tstamp);
			let desc = '';
			if (item.desc) {
				if (Array.isArray(item.desc) && item.desc.length > 0) {
					desc = item.desc.join('<br >');
				} else {
					desc = item.desc;
				}
				text = PrepareDescText(moment.utc(item.tstamp).format('DD.MMM.YYYY HH:mm'), desc);

				if (item.desc.length === 1) {
					plotLineColorAndId.color = PickColorOfPlotLine(item.desc[0]);
				} else if (!Array.isArray(item.desc)) {
					plotLineColorAndId.color = PickColorOfPlotLine(item.desc);
				}
			}
			xAxis.addPlotLine({
				width: 2,
				value: m,
				...plotLineColorAndId,
				zIndex: 5,
				label: {
					text: '',
					verticalAlign: 'top',
					rotation: 0,
					align: 'center',
					useHTML: true,
					style: {
						color: 'black',
						fontWeight: 'bold',
						backgroundColor: 'rgba(232, 252, 251, .6)',
					},
				},
				events: {
					mouseenter: function (_) {
						this.svgElem.attr('stroke-width', this.svgElem.attr('stroke-width') + 2);
						this.label.attr('text', text);
						return true;
					},
					mouseout: function (_) {
						this.svgElem.attr('stroke-width', this.svgElem.attr('stroke-width') - 2);
						this.label.attr('text', '');
						return true;
					},
				},
			});
		}
		if (this.legendUpdateFun) this.legendUpdateFun();
	}

	setPlotBandsVisible = (container, xAxis, visible, additionalPlotBandProperties) => {
		if (container && visible) {
			for (let i = 0; i < container.length; i += 2) {
				xAxis.addPlotBand({
					from: container[i].tstamp,
					to: container[i + 1].tstamp,
					...additionalPlotBandProperties,
				});
			}
		} else {
			xAxis.removePlotBand(additionalPlotBandProperties.id);
		}
		if (this.legendUpdateFun) this.legendUpdateFun();
	};

	addLineLegendItem = (name, color, chart = null) => {
		if (chart === null) {
			if (!this.chart.series.some(item => item.name === name)) {
				this.chart.addSeries(this.makeLineLegendItemFrom0Series(name, color));
			}
		} else {
			chart.addSeries(this.makeLineLegendItemFrom0Series(name, color));
		}
	};

	removedLineLegendItem = name => {
		const index = this.chart.series.findIndex(item => item.name === name);
		if (index >= 0) {
			this.chart.series[index].remove(true);
		}
	};

	makeLineLegendItemFrom0Series = (name, color) => {
		return {
			// Series that mimics the plot line
			name: name,
			color: color,
			dashStyle: 'line',
			marker: {
				enabled: false,
			},
			events: {
				legendItemClick: function () {
					return false;
				},
			},
		};
	};

	addSensorErrorLegend = (error, chart) => {
		const errorColor = PLOT_LINE_COLORS[error.replaceAll(' ', '_').toUpperCase()];
		this.addSeriesToChart(chart, 'Sensor Error: ' + error, errorColor);
	};

	DrawStartRunStopRun = () => {
		let showCheckBox = false;
		this.startStopRuns = [];
		const legends = [];
		let showStartRunLegend = false;
		let showStopRunLegend = false;
		let isEnabled = false;

		if (this.props.startRun) {
			this.startStopRuns.push({
				desc: RUN_EVENTS.START_RUN[0],
				tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(this.props.startRun),
			});
			legends.push({
				legendDesc: RUN_EVENTS.START_RUN[1],
			});
			if (isEnabled) {
				showStartRunLegend = true;
			}
			showCheckBox = true;
		}

		if (this.props.stopRun) {
			this.startStopRuns.push({
				desc: RUN_EVENTS.STOP_RUN[0],
				tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(this.props.stopRun),
			});
			legends.push({
				legendDesc: RUN_EVENTS.STOP_RUN[1],
			});
			if (isEnabled) {
				showStopRunLegend = true;
			}
			showCheckBox = true;
		}

		this.setState(prevState => ({
			startRunStopRunSettings: {
				...prevState.startRunStopRunSettings,
				showStartRunStopRunCheckBox: showCheckBox,
				isEnabled: isEnabled,
				legends: legends,
				showStartRunLegend: showStartRunLegend,
				showStopRunLegend: showStopRunLegend,
			},
		}));
	};

	DrawAlarmingOnAlarmingOff = () => {
		let showCheckBox = false;
		this.alarmingOnOff = [];
		const legends = [];
		let showAlarmingOffLegend = false;
		let showAlarmingOnLegend = false;
		let isEnabled = false;

		if (this.menuAlarmingOnOffOccurrences && this.menuAlarmingOnOffOccurrences.length > 0) {
			this.menuAlarmingOnOffOccurrences.forEach(s => {
				const tstamp = s.tstamp;
				if (s.metadata['alarming_on'] === ALARM_EVENTS.ALARMING_OFF[2]) {
					this.alarmingOnOff.push({
						desc: ALARM_EVENTS.ALARMING_OFF[0],
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					legends.push({
						legendDesc: String(ALARM_EVENTS.ALARMING_OFF[1]),
					});
					if (isEnabled) {
						showAlarmingOffLegend = true;
					}
					showCheckBox = true;
				} else if (s.metadata['alarming_on'] === ALARM_EVENTS.ALARMING_ON[2]) {
					this.alarmingOnOff.push({
						desc: String(ALARM_EVENTS.ALARMING_ON[0]),
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					legends.push({
						legendDesc: String(ALARM_EVENTS.ALARMING_ON[1]),
					});
					if (isEnabled) {
						showAlarmingOnLegend = true;
					}
					showCheckBox = true;
				}
			});
		}

		this.setState(prevState => ({
			alarmingOnAlarmingOffSettings: {
				...prevState.alarmingOnAlarmingOffSettings,
				showAlarmingOnAlarmingOffCheckBox: showCheckBox,
				isEnabled: isEnabled,
				legends: legends,
				showAlarmingOnLegend: showAlarmingOnLegend,
				showAlarmingOffLegend: showAlarmingOffLegend,
			},
		}));
	};

	DrawFlightModeOnOff = () => {
		let CountOfFlightModeOn = 0;
		let flightDetected = false;
		let flightStartDate = undefined;
		let showCheckBox = false;
		this.flightModeOnOff = [];
		const legends = [];
		let showFlightModeOnLegend = false;
		let showFlightModeOffLegend = false;

		if (this.flightModeOnOffOccurrences && this.flightModeOnOffOccurrences.length > 0) {
			this.flightModeOnOffOccurrences.forEach(s => {
				const tstamp = s.tstamp;
				if (CountOfFlightModeOn > 0 && s.metadata['flight_detected'] === FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[2]) {
					CountOfFlightModeOn--;
					flightDetected = false;
					let diffMs = +tstamp - +flightStartDate;
					const diffMins = Math.floor(diffMs / 1000 / 60);
					if (diffMins > 30) {
						this.flightModeOnOff.push(
							{
								desc: String(FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[0]),
								tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(flightStartDate),
							},
							{
								desc: String(FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[0]),
								tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
							}
						);
						legends.push(
							{
								legendDesc: String(FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[1]),
							},
							{
								legendDesc: String(FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[1]),
							}
						);
						showFlightModeOnLegend = true;
						showFlightModeOffLegend = true;
						showCheckBox = true;
					}
				} else if (s.metadata['flight_detected'] === FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[2]) {
					if (!flightDetected) {
						CountOfFlightModeOn++;
						flightDetected = true;
						flightStartDate = tstamp;
					}
				}
			});
		}

		this.setState(prevState => ({
			flightModeOnOffSettings: {
				...prevState.flightModeOnOffSettings,
				showFlightModeOnOffCheckBox: showCheckBox,
				legends: legends,
				showFlightModeOnLegend: showFlightModeOnLegend,
				showFlightModeOffLegend: showFlightModeOffLegend,
			},
		}));
	};

	DrawLoggerStatuses = () => {
		let showCheckBox = false;
		this.logStatuses = [];
		const legends = [];
		let showStartLegend = false; // START
		let showLogDelayedLegend = false; //LOG DELAYED
		let showLogTransitLegend = false; //LOG TRANSIT
		let showLogPausedLegend = false; //LOG PAUSED
		let showLogArrivedLegend = false; //LOG ARRIVED
		let showLogStoppedLegend = false; //LOG STOPPED
		let showCalibLegend = false; //CALIB

		if (this.occurrences && this.occurrences.length > 0) {
			this.occurrences.forEach(s => {
				const tstamp = s.tstamp;
				if (s.state === LOGGER_STATUS_EVENTS.START[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.START[0],
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					legends.push({
						legendDesc: LOGGER_STATUS_EVENTS.START[1],
					});
					showStartLegend = true;
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.LOG_DELAY[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.LOG_DELAY[0],
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					legends.push({
						legendDesc: LOGGER_STATUS_EVENTS.LOG_DELAY[1],
					});
					showLogDelayedLegend = true;
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.LOG_TRANSIT[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.LOG_TRANSIT[0],
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					legends.push({
						legendDesc: LOGGER_STATUS_EVENTS.LOG_TRANSIT[1],
					});
					showLogTransitLegend = true;
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.LOG_PAUSED[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.LOG_PAUSED[0],
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					legends.push({
						legendDesc: LOGGER_STATUS_EVENTS.LOG_PAUSED[1],
					});
					showLogPausedLegend = true;
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.LOG_ARRIVED[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.LOG_ARRIVED[0],
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					legends.push({
						legendDesc: LOGGER_STATUS_EVENTS.LOG_ARRIVED[1],
					});
					showLogArrivedLegend = true;
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.LOG_STOPPED[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.LOG_STOPPED[0],
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					legends.push({
						legendDesc: LOGGER_STATUS_EVENTS.LOG_STOPPED[1],
					});
					showLogStoppedLegend = true;
					showCheckBox = true;
				} else if (s.state === LOGGER_STATUS_EVENTS.CALIB[1]) {
					this.logStatuses.push({
						desc: LOGGER_STATUS_EVENTS.CALIB[0],
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					legends.push({
						legendDesc: LOGGER_STATUS_EVENTS.CALIB[1],
					});
					showCalibLegend = true;
					showCheckBox = true;
				}
			});
		}

		this.setState(prevState => ({
			logStatusesSettings: {
				...prevState.logStatusesSettings,
				showLogStatusesCheckBox: showCheckBox,
				legends: legends,
				showStartLegend: showStartLegend,
				showLogDelayedLegend: showLogDelayedLegend,
				showLogTransitLegend: showLogTransitLegend,
				showLogPausedLegend: showLogPausedLegend,
				showLogArrivedLegend: showLogArrivedLegend,
				showLogStoppedLegend: showLogStoppedLegend,
				showCalibLegend: showCalibLegend,
			},
		}));
	};

	DrawSensorLimitAlarms = () => {
		let showCheckBox = false;
		this.sensorLimitAlarms = [];
		const legends = [];
		let showSensorUpperLimitAlarmLegend = false;
		let showSensorLowerLimitAlarmLegend = false;

		if (this.props.sensorLimitAlarms) {
			for (const alarm of this.props.sensorLimitAlarms) {
				const desc = alarm.desc;
				const tstamp = DateTimeUtils.utcOffset_date_dep(alarm.tstamp).format('YYYY-MM-DDTHH:mm') + '+00:00';
				if (desc === SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[1]) {
					this.sensorLimitAlarms.push({
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[0],
					});
					legends.push({
						legendDesc: SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[1],
					});
					showSensorUpperLimitAlarmLegend = true;
					showCheckBox = true;
				} else if (desc === SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[1]) {
					this.sensorLimitAlarms.push({
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[0],
					});
					legends.push({
						legendDesc: SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[1],
					});
					showSensorLowerLimitAlarmLegend = true;
					showCheckBox = true;
				}
			}
		}

		this.setState(prevState => ({
			sensorLimitAlarmsSettings: {
				...prevState.sensorLimitAlarmsSettings,
				showSensorLimitAlarmsCheckBox: showCheckBox,
				legends: legends,
				showSensorUpperLimitAlarmLegend: showSensorUpperLimitAlarmLegend,
				showSensorLowerLimitAlarmLegend: showSensorLowerLimitAlarmLegend,
			},
		}));

		if (this.state.sensorLimitAlarmsSettings.isEnabled) {
			this.setAllVisible(this.chart);
		}
	};

	DrawSensorIssueAlarms = () => {
		let showCheckBox = false;
		this.sensorErrorValues = [];
		this.sensorIssueAlarms = [];
		const legends = [];
		let showRadioConnectionWarningLegend = false;
		let showLostMeasurementAlarmLegend = false;
		let showLowBatteryWarningLegend = false;
		let showMissingCommunicationWarningLegend = false;
		let showMissingValueAlarmLegend = false;
		let showSensorFailureAlarmLegend = false;

		if (this.props.sensorErrors && this.props.sensorErrors.length > 0) {
			this.sensorErrorValues = this.props.sensorErrors.reduce((group, error) => {
				const {type} = error;
				const errorColor = PLOT_LINE_COLORS[type.replaceAll(' ', '_').toUpperCase()] ?? PLOT_LINE_COLORS.SENSOR_ERROR;
				group[type] = group[type]
					? group[type]
					: {
							tstamps: [],
							color: errorColor,
					  };
				group[type].tstamps.push({tstamp: error.start});
				group[type].tstamps.push({tstamp: error.end});
				return group;
			}, {});

			showCheckBox = true;
		}

		if (this.props.sensorIssueAlarms) {
			for (const alarm of this.props.sensorIssueAlarms) {
				const desc = alarm.desc;
				const tstamp = DateTimeUtils.utcOffset_date_dep(alarm.tstamp).format('YYYY-MM-DDTHH:mm') + '+00:00';
				if (desc === SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[1]) {
					this.sensorIssueAlarms.push({
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[0],
					});
					legends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[1],
					});
					showRadioConnectionWarningLegend = true;
					showCheckBox = true;
				} else if (desc === SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[1]) {
					this.sensorIssueAlarms.push({
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[0],
					});
					legends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[1],
					});
					showLowBatteryWarningLegend = true;
					showCheckBox = true;
				} else if (desc === SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[1]) {
					this.sensorIssueAlarms.push({
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[0],
					});
					legends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[1],
					});
					showLowBatteryWarningLegend = true;
					showCheckBox = true;
				} else if (desc === SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[1]) {
					this.sensorIssueAlarms.push({
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[0],
					});
					legends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[1],
					});
					showMissingValueAlarmLegend = true;
					showCheckBox = true;
				} else if (desc === SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[1]) {
					this.sensorIssueAlarms.push({
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[0],
					});
					legends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[1],
					});
					showMissingCommunicationWarningLegend = true;
					showCheckBox = true;
				} else if (desc === SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[1]) {
					this.sensorIssueAlarms.push({
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
						desc: SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[0],
					});
					legends.push({
						legendDesc: SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[1],
					});
					showSensorFailureAlarmLegend = true;
					showCheckBox = true;
				}
			}
		}

		this.setState(prevState => ({
			sensorIssueAlarmsSettings: {
				...prevState.sensorIssueAlarmsSettings,
				showSensorIssueAlarmsCheckBox: showCheckBox,
				legends: legends,
				showRadioConnectionWarningLegend: showRadioConnectionWarningLegend,
				showLostMeasurementAlarmLegend: showLostMeasurementAlarmLegend,
				showLowBatteryWarningLegend: showLowBatteryWarningLegend,
				showMissingCommunicationWarningLegend: showMissingCommunicationWarningLegend,
				showMissingValueAlarmLegend: showMissingValueAlarmLegend,
				showSensorFailureAlarmLegend: showSensorFailureAlarmLegend,
			},
		}));
	};

	DrawMenuSetMark = () => {
		let showCheckBox = false;
		this.MenuSetMark = [];
		const legends = [];
		let showMenuSetMarkLegend = false;

		if (this.menuSetMarkOccurrences && this.menuSetMarkOccurrences.length > 0) {
			this.menuSetMarkOccurrences.forEach(s => {
				const tstamp = s.tstamp;
				if (s.metadata['mark_counter']) {
					const desc = MENU_SET_MARK_EVENTS.MARK_COUNTER[0] + ' : ' + s.metadata['mark_counter'];
					this.MenuSetMark.push({
						desc: desc,
						tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					});
					legends.push({
						legendDesc: MENU_SET_MARK_EVENTS.MARK_COUNTER[1],
					});
					showMenuSetMarkLegend = true;
					showCheckBox = true;
				}
			});
		}

		this.setState(prevState => ({
			menuSetMarkSettings: {
				...prevState.menuSetMarkSettings,
				showMenuSetMarkCheckBox: showCheckBox,
				legends: legends,
				showMenuSetMarkLegend: showMenuSetMarkLegend,
			},
		}));
	};

	DrawSensorReplaces = () => {
		let showCheckBox = false;
		const legends = [];
		let showSensorReplacesLegend = false;

		if (this.props.sensorReplaces) {
			for (const sensorReplace of this.props.sensorReplaces) {
				const desc = sensorReplace.desc;
				const tstamp = DateTimeUtils.utcOffset_date_dep(sensorReplace.tstamp).format('YYYY-MM-DDTHH:mm') + '+00:00';

				this.sensorReplaces.push({
					tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(tstamp),
					desc: desc,
				});
				legends.push({
					legendDesc: REPLACE_SENSOR_EVENTS.REPLACE_SENSOR[1],
				});
				showSensorReplacesLegend = true;
				showCheckBox = true;
			}
		}

		this.setState(prevState => ({
			sensorReplacesSettings: {
				...prevState.sensorReplacesSettings,
				showSensorReplacesCheckBox: showCheckBox,
				legends: legends,
				showSensorReplacesLegend: showSensorReplacesLegend,
			},
		}));
	};

	drawGraphic = () => {
		let deferred = false;
		let bypassed = false;
		this.deferredDataPoints = [];
		this.occurrences = [];
		this.menuAlarmingOnOffOccurrences = [];
		this.flightModeOnOffOccurrences = [];
		this.menuSetMarkOccurrences = [];
		this.all = [];
		this.startStopRuns = [];
		this.alarmingOnOff = [];
		this.flightModeOnOff = [];
		this.logStatuses = [];
		this.sensorLimitAlarms = [];
		this.sensorIssueAlarms = [];
		this.MenuSetMark = [];
		this.sensorReplaces = [];
		this.communication_times = [];
		this.bypassedDataPoints = [];
		this.sensorErrorValues = [];
		this.sensorCalibrations = [];

		for (const dataPoint of this.props.measurements) {
			if (dataPoint.bypassed !== bypassed) {
				this.bypassedDataPoints.push({
					isBypassed: dataPoint.bypassed,
					tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(dataPoint.tstamp),
					value: SensorUtils.convertTemperature(dataPoint.value, this.props.sensor.out_units_id),
				});

				bypassed = dataPoint.bypassed;
			}

			if (dataPoint.deferred !== deferred) {
				this.deferredDataPoints.push({
					isDeferred: dataPoint.deferred,
					tstamp: SensorUtils.convertPostgresqlTimestampToHighcharts(dataPoint.tstamp),
				});

				deferred = dataPoint.deferred;
			}

			this.series[0].data.push({
				x: SensorUtils.convertPostgresqlTimestampToHighcharts(dataPoint.tstamp),
				y: SensorUtils.convertTemperature(dataPoint.value, this.props.sensor.out_units_id),
			});
		}

		if (this.props.sensorCalibrations) {
			for (const sensorCalibration of this.props.sensorCalibrations) {
				this.sensorCalibrations.push({tstamp: sensorCalibration.start});
				this.sensorCalibrations.push({tstamp: sensorCalibration.end});
			}
		}

		if (this.props.occurrences) {
			for (const occurrence of this.props.occurrences) {
				const type_name = occurrence.type_name;
				const tstamp = SensorUtils.convertPostgresqlTimestampToHighcharts(occurrence.tstamp);
				if (type_name === OCCURRENCES.REQUIRED_TYPES[0]) {
					const newState = occurrence.new_state;
					this.occurrences.push({
						tstamp: tstamp,
						state: newState,
					});
				} else if (type_name === OCCURRENCES.REQUIRED_TYPES[1]) {
					this.communication_times.push(tstamp);
				}
			}
		}

		if (this.props.otherOccurrences) {
			for (const occurrence of this.props.otherOccurrences) {
				const typeName = occurrence.type_name;
				const timestamp = SensorUtils.convertPostgresqlTimestampToHighcharts(occurrence.tstamp);

				if (typeName === OTHER_OCCURRENCES.REQUIRED_TYPES[0]) {
					const metadata = occurrence.metadata;
					this.flightModeOnOffOccurrences.push({
						tstamp: timestamp,
						metadata: metadata,
					});
				} else if (typeName === OTHER_OCCURRENCES.REQUIRED_TYPES[1]) {
					const metadata = occurrence.metadata;
					this.menuAlarmingOnOffOccurrences.push({
						tstamp: timestamp,
						metadata: metadata,
					});
				} else if (typeName === OTHER_OCCURRENCES.REQUIRED_TYPES[2]) {
					const metadata = occurrence.metadata;
					this.menuSetMarkOccurrences.push({
						tstamp: timestamp,
						metadata: metadata,
					});
				}
			}
		}

		const addLineLegendItem = (name, color) => {
			this.series.push(this.makeLineLegendItemFrom0Series(name, color));
		};

		if (this.props.deviationStart) {
			addLineLegendItem('Deviation start', 'red');
		}

		if (this.props.deviationEnd) {
			addLineLegendItem('Deviation end', 'grey');
		}

		const getLastDataElmOfSeries = series => {
			return series.data ? series.data[series.data.length - 1] : null;
		};

		if (SensorUtils.isNumberOdd(this.occurrences.length)) {
			const seriesDataLast = getLastDataElmOfSeries(this.series[0]);
			if (seriesDataLast) {
				this.occurrences.push({
					tstamp: seriesDataLast.x,
				});
			}
		}

		if (SensorUtils.isNumberOdd(this.bypassedDataPoints.length)) {
			if (this.bypassedDataPoints[this.bypassedDataPoints.length - 1].isBypassed) {
				const seriesDataLast = getLastDataElmOfSeries(this.series[0]);
				if (seriesDataLast) {
					this.bypassedDataPoints.push({
						isBypassed: false,
						tstamp: seriesDataLast.x,
					});
				}
			}
		}

		if (SensorUtils.isNumberOdd(this.deferredDataPoints.length)) {
			if (this.deferredDataPoints[this.deferredDataPoints.length - 1].isDeferred) {
				const seriesDataLast = getLastDataElmOfSeries(this.series[0]);
				if (seriesDataLast) {
					this.deferredDataPoints.push({
						isDeferred: false,
						tstamp: seriesDataLast.x,
					});
				}
			}
		}
	};

	drawAlarmLimits = () => {
		if (this.props.limitAlarms) {
			for (const alarm of this.props.limitAlarms) {
				let details = alarm.details;
				if (!Array.isArray(details)) continue;
				for (const detail of details) {
					this.drawAlarmBand(detail, alarm.valid_from, alarm.valid_until);
				}
			}
		}
	};

	drawAlarmBand = (detail, valid_from, valid_until) => {
		if (detail.is_muted) {
			return;
		}

		if (this.props.measurements?.length) {
			if (new Date(valid_from).getTime() < new Date(this.props.measurements[0].tstamp).getTime()) {
				valid_from = new Date(this.props.measurements[0].tstamp);
			}
			if (new Date(valid_until).getTime() > new Date(this.props.measurements[this.props.measurements.length - 1].tstamp).getTime()) {
				valid_until = new Date(this.props.measurements[this.props.measurements.length - 1].tstamp);
			}
		}

		const givenUpperLimits = [];
		detail.upper_limit != null && givenUpperLimits.push(detail.upper_limit);
		detail.upper_limit_2 != null && givenUpperLimits.push(detail.upper_limit_2);
		detail.upper_limit_3 != null && givenUpperLimits.push(detail.upper_limit_3);
		detail.upper_limit_4 != null && givenUpperLimits.push(detail.upper_limit_4);
		detail.upper_limit != null && givenUpperLimits.push(Number.MAX_VALUE);

		const givenLowerLimits = [];
		detail.lower_limit != null && givenLowerLimits.push(detail.lower_limit);
		detail.lower_limit_2 != null && givenLowerLimits.push(detail.lower_limit_2);
		detail.lower_limit_3 != null && givenLowerLimits.push(detail.lower_limit_3);
		detail.lower_limit != null && givenLowerLimits.push(-Number.MAX_VALUE);

		for (let i = 0; i < givenUpperLimits.length - 1; i++) {
			this.alarmZones.push({
				from: givenUpperLimits[i + 1],
				to: givenUpperLimits[i],
				color: PLOT_LINE_COLORS[`H${i + 1}LIMIT`],
				start: new Date(valid_from),
				end: new Date(valid_until),
				id: `H${i + 1}LIMIT`,
			});
		}

		for (let i = 0; i < givenLowerLimits.length - 1; i++) {
			this.alarmZones.push({
				from: givenLowerLimits[i + 1],
				to: givenLowerLimits[i],
				color: PLOT_LINE_COLORS[`L${i + 1}LIMIT`],
				start: new Date(valid_from),
				end: new Date(valid_until),
				id: `L${i + 1}LIMIT`,
			});
		}
	};

	setOccurrencesVisible = (chart, visible) => {
		this.setPlotBandsVisible(this.occurrences, chart.xAxis[0], visible, {
			color: 'rgba(0, 183, 255, 0.1)',
			id: 'plot-band-occurrences',
		});
	};

	setAllVisible = chart => {
		if (!chart?.options) {
			return;
		}

		try {
			this.all = [];

			if (this.state.startRunStopRunSettings.isEnabled) {
				this.all = [...this.startStopRuns, ...this.all];
			}

			if (this.state.alarmingOnAlarmingOffSettings.isEnabled) {
				this.all = [...this.alarmingOnOff, ...this.all];
			}

			if (this.state.flightModeOnOffSettings.isEnabled) {
				this.all = [...this.flightModeOnOff, ...this.all];
			}

			if (this.state.logStatusesSettings.isEnabled) {
				this.all = [...this.logStatuses, ...this.all];
			}

			if (this.state.sensorLimitAlarmsSettings.isEnabled) {
				this.all = [...this.sensorLimitAlarms, ...this.all];
			}

			if (this.state.sensorIssueAlarmsSettings.isEnabled) {
				this.all = [...this.sensorIssueAlarms, ...this.all];
			}

			if (this.state.menuSetMarkSettings.isEnabled) {
				this.all = [...this.MenuSetMark, ...this.all];
			}

			if (this.state.sensorReplacesSettings.isEnabled) {
				this.all = [...this.sensorReplaces, ...this.all];
			}

			let groupedAlarmStatuses = GroupAlarmStatusesAccordingToDate(this.all);
			const sortedAlarms = groupedAlarmStatuses.sort((a, b) => a.tstamp - b.tstamp);
			const events = chart.series.find(s => s.userOptions.id === 'events');
			if (sortedAlarms?.length && events) {
				events.data = [];
				events.addPoint({
					x: sortedAlarms[0].tstamp,
					y: null,
				});

				events.addPoint({
					x: sortedAlarms[sortedAlarms.length - 1].tstamp,
					y: null,
				});

				events.visible = true;
			}
			let alarmStatusesWithMoreThanOneDesc = [];
			let alarmStatusesWithOneDesc = [];
			for (const alarmStatus of groupedAlarmStatuses) {
				if (alarmStatus.desc.length > 1) {
					alarmStatusesWithMoreThanOneDesc.push(alarmStatus);
				} else {
					alarmStatusesWithOneDesc.push(alarmStatus);
				}
			}

			chart.xAxis[0].removePlotLine('alarm-statuses-with-more-than-one-desc');
			chart.xAxis[0].removePlotLine('alarm-statuses-with-one-desc');
			chart.xAxis[0].removePlotLine('plot-line-all');

			this.setPlotLinesVisible(alarmStatusesWithMoreThanOneDesc, chart.xAxis[0], {
				color: '#02000d',
				id: 'alarm-statuses-with-more-than-one-desc',
			});

			this.setPlotLinesVisible(alarmStatusesWithOneDesc, chart.xAxis[0], {
				color: '#05fc2e',
				id: 'alarm-statuses-with-one-desc',
			});

			this.setSensorErrorValuesVisible(chart, this.state.sensorIssueAlarmsSettings.isEnabled);
		} catch (ex) {
			console.error(ex);
		}
	};

	setReloadedValuesVisible = (chart, visible) => {
		this.setPlotBandsVisible(this.deferredDataPoints, chart.xAxis[0], visible, {
			color: PLOT_LINE_COLORS.RELOADED_VALUES,
			id: 'plot-band-deferred',
		});
	};

	setSensorCalibrationsVisible = (chart, visible) => {
		this.setPlotBandsVisible(this.sensorCalibrations, chart.xAxis[0], visible, {
			color: PLOT_LINE_COLORS.CALIBRATION,
			id: 'plot-band-calibration',
		});
	};

	setBypassedValuesVisible = (chart, visible) => {
		this.setPlotBandsVisible(this.bypassedDataPoints, chart.xAxis[0], visible, {
			color: PLOT_LINE_COLORS.ALARMING_INACTIVE,
			id: 'plot-band-bypassed',
		});
	};

	setSensorErrorValuesVisible = (chart, visible) => {
		Object.keys(this.sensorErrorValues).forEach(errorType => {
			if (visible && chart.series.findIndex(s => s.name.includes(errorType)) === -1) {
				this.addSensorErrorLegend(errorType, chart);
			}
		});

		for (const errorType in this.sensorErrorValues) {
			const error = this.sensorErrorValues[errorType];
			this.setPlotBandsVisible(error.tstamps, chart.xAxis[0], visible, {
				color: error.color,
				id: 'plot-band-' + errorType,
				events: {
					mousemove(e) {
						let x = e.offsetX + 4,
							y = e.offsetY + 8;

						if (!chart.annotationTooltip) {
							chart.annotationTooltip = chart.renderer
								.label(errorType, x, y, 'callout')
								.css({
									color: '#ffffff',
								})
								.attr({
									fill: 'rgba(0, 0, 0, 0.75)',
									zIndex: 10,
									padding: 5,
									r: 5,
								})
								.add();
						} else {
							chart.annotationTooltip.attr({
								x,
								y,
							});
						}
					},
					mouseout() {
						if (chart.annotationTooltip) {
							chart.annotationTooltip.destroy();
							chart.annotationTooltip = undefined;
						}
					},
				},
			});
		}
	};

	setMeasurementUploadsVisible = (cnt, chart, visible) => {
		cnt.communication_times.map(tstamp => {
			const m = moment(tstamp);
			const axis = chart.xAxis[0];
			if (visible) {
				axis.addPlotLine({
					color: PLOT_LINE_COLORS.COMMUNICATION_TIMES,
					width: 1.5,
					value: m,
					className: 'sensor-communication',
					id: m.valueOf(),
					label: {
						text: '',
						verticalAlign: 'top',
						align: 'center',
						rotation: 0,
					},
					events: {
						mouseenter: function (_) {
							this.svgElem.attr('stroke-width', this.svgElem.attr('stroke-width') + 2);
							this.label.attr('text', moment.utc(m).format('DD.MMM.YYYY HH:mm'));
							return true; // <== returning false will cancel the default action
						},
						mouseout: function (_) {
							this.svgElem.attr('stroke-width', this.svgElem.attr('stroke-width') - 2);
							this.label.attr('text', '');
							return true; // <== returning false will cancel the default action
						},
					},
				});
			} else {
				axis.removePlotLine(m.valueOf());
			}
		});

		if (this.legendUpdateFun) this.legendUpdateFun();
	};

	onShowReloadedValuesClick = _ => {
		this.setState(
			prevState => ({showReloadedValues: !prevState.showReloadedValues}),
			() => this.setReloadedValuesVisible(this.chart, this.state.showReloadedValues)
		);
	};

	onShowMeasurementUploadsClick = _ => {
		this.setState(
			prevState => ({showMeasurementUploads: !prevState.showMeasurementUploads}),
			() => this.setMeasurementUploadsVisible(this, this.chart, this.state.showMeasurementUploads)
		);
	};

	onShowSensorCalibrationsClick = _ => {
		this.setState(
			prevState => ({showSensorCalibrationValues: !prevState.showSensorCalibrationValues}),
			() => this.setSensorCalibrationsVisible(this.chart, this.state.showSensorCalibrationValues)
		);
	};

	onShowStartRunStopRunClick = _ => {
		this.toggleVisibility('startRunStopRunSettings', [
			{legendKey: 'showStartRunLegend', type: RUN_EVENTS.START_RUN, color: PLOT_LINE_COLORS.START_STOP_RUN},
			{legendKey: 'showStopRunLegend', type: RUN_EVENTS.STOP_RUN, color: PLOT_LINE_COLORS.START_STOP_RUN},
		]);
	};

	onShowAlarmingOnAlarmingOffRunClick = _ => {
		this.toggleVisibility('alarmingOnAlarmingOffSettings', [
			{legendKey: 'showAlarmingOnLegend', type: ALARM_EVENTS.ALARMING_ON, color: PLOT_LINE_COLORS.ALARMING_ON_OFF},
			{legendKey: 'showAlarmingOffLegend', type: ALARM_EVENTS.ALARMING_OFF, color: PLOT_LINE_COLORS.ALARMING_ON_OFF},
		]);
	};

	onShowFlightModeOnOffClick = _ => {
		this.toggleVisibility('flightModeOnOffSettings', [
			{
				legendKey: 'showFlightModeOnLegend',
				type: FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON,
				color: PLOT_LINE_COLORS.FLIGHT_MODE_ON_OFF,
			},
			{
				legendKey: 'showFlightModeOffLegend',
				type: FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF,
				color: PLOT_LINE_COLORS.FLIGHT_MODE_ON_OFF,
			},
		]);
	};

	onShowLogStatusesClick = _ => {
		this.toggleVisibility('logStatusesSettings', [
			{legendKey: 'showStartLegend', type: LOGGER_STATUS_EVENTS.START, color: PLOT_LINE_COLORS.LOGGERS_STATUSES},
			{legendKey: 'showLogDelayedLegend', type: LOGGER_STATUS_EVENTS.LOG_DELAY, color: PLOT_LINE_COLORS.LOGGERS_STATUSES},
			{legendKey: 'showLogTransitLegend', type: LOGGER_STATUS_EVENTS.LOG_TRANSIT, color: PLOT_LINE_COLORS.LOGGERS_STATUSES},
			{legendKey: 'showLogPausedLegend', type: LOGGER_STATUS_EVENTS.LOG_PAUSED, color: PLOT_LINE_COLORS.LOGGERS_STATUSES},
			{legendKey: 'showLogArrivedLegend', type: LOGGER_STATUS_EVENTS.LOG_ARRIVED, color: PLOT_LINE_COLORS.LOGGERS_STATUSES},
			{legendKey: 'showLogStoppedLegend', type: LOGGER_STATUS_EVENTS.LOG_STOPPED, color: PLOT_LINE_COLORS.LOGGERS_STATUSES},
			{legendKey: 'showCalibLegend', type: LOGGER_STATUS_EVENTS.CALIB, color: PLOT_LINE_COLORS.LOGGERS_STATUSES},
		]);
	};

	onShowSensorLimitAlarmsClick = _ => {
		this.toggleVisibility('sensorLimitAlarmsSettings', [
			{
				legendKey: 'showSensorUpperLimitAlarmLegend',
				type: SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM,
				color: PLOT_LINE_COLORS.SENSOR_LIMIT_ALARMS,
			},
			{
				legendKey: 'showSensorLowerLimitAlarmLegend',
				type: SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM,
				color: PLOT_LINE_COLORS.SENSOR_LIMIT_ALARMS,
			},
		]);
	};

	onShowSensorIssueAlarmsClick = _ => {
		this.toggleVisibility('sensorIssueAlarmsSettings', [
			{
				legendKey: 'showRadioConnectionWarningLegend',
				type: SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING,
				color: PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
			},
			{
				legendKey: 'showLostMeasurementAlarmLegend',
				type: SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM,
				color: PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
			},
			{
				legendKey: 'showLowBatteryWarningLegend',
				type: SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING,
				color: PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
			},
			{
				legendKey: 'showMissingValueAlarmLegend',
				type: SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM,
				color: PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
			},
			{
				legendKey: 'showMissingCommunicationWarningLegend',
				type: SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING,
				color: PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
			},
			{
				legendKey: 'showSensorFailureAlarmLegend',
				type: SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM,
				color: PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
			},
		]);
	};

	onShowMenuSetMarkClick = _ => {
		this.toggleVisibility('menuSetMarkSettings', [
			{legendKey: 'showMenuSetMarkLegend', type: MENU_SET_MARK_EVENTS.MARK_COUNTER, color: PLOT_LINE_COLORS.MENU_SET_MARK},
		]);
	};

	onShowReplaceSensorClick = _ => {
		this.toggleVisibility('sensorReplacesSettings', [
			{legendKey: 'showSensorReplacesLegend', type: REPLACE_SENSOR_EVENTS.REPLACE_SENSOR, color: PLOT_LINE_COLORS.REPLACE_SENSOR},
		]);
	};

	toggleVisibility = (settingsKey: string, legends: {legendKey: string; type: (string | number)[]; color: string}[]) => {
		const settings = {...this.state[settingsKey]};
		settings.isEnabled = !settings.isEnabled;
		legends.forEach(({legendKey, type, color}) => {
			if (settings.isEnabled && settings[legendKey]) {
				this.addLineLegendItem(type[0], color);
			} else {
				this.removedLineLegendItem(type[0]);
			}
		});

		this.setState(
			prev => {
				prev[settingsKey] = settings;
			},
			() => this.setAllVisible(this.chart)
		);
	};

	updateAlarms(showBypassed: boolean) {
		if (this.chart.axes[0].displayBtn) {
			// if in zoom
			this.chart.series.forEach(s => {
				if (s.options.is_muted) {
					if (showBypassed) {
						s.update({color: '#FF0000'}, true);
					} else {
						s.update({color: '#B1B3B3'}, true);
					}
				}
			});
		}
	}

	onShowBypassedValuesClick = _ => {
		this.setState(
			state => ({showBypassedValues: !state.showBypassedValues}),
			() => {
				this.setBypassedValuesVisible(this.chart, this.state.showBypassedValues);
				this.updateAlarms(this.state.showBypassedValues);
			}
		);
	};

	addSeriesToChart = (chart, name, color) => {
		chart.addSeries({
			name: name,
			color: color,
			lineWidth: 14,
			marker: {
				enabled: false,
			},
			events: {
				legendItemClick: function () {
					return false;
				},
			},
		});
	};

	afterRender = (cnt, chart) => {
		if (cnt?.alarmZones?.length > 0) {
			for (const zone of cnt.alarmZones) {
				chart.yAxis[0].addPlotBand(zone);
				const zoneName = Trans({i18nKey: `chart.${zone.id}`})[0];
				if (!chart.series.find(s => s.name.includes(zoneName))) {
					this.addSeriesToChart(chart, zoneName, zone.color);
				}
			}
		}

		if (chart.series.length > 0) {
			if (!chart.xAxis[0].isDirty) {
				this.setReloadedValuesVisible(chart, cnt.state.showReloadedValues);
				this.setBypassedValuesVisible(chart, cnt.state.showBypassedValues);
				this.setOccurrencesVisible(chart, cnt.state.showInTransit);
				this.setSensorCalibrationsVisible(chart, cnt.state.showSensorCalibrationValues);

				if (
					this.state.startRunStopRunSettings.isEnabled ||
					this.state.alarmingOnAlarmingOffSettings.isEnabled ||
					this.state.flightModeOnOffSettings.isEnabled ||
					this.state.logStatusesSettings.isEnabled ||
					this.state.sensorLimitAlarmsSettings.isEnabled ||
					this.state.sensorIssueAlarmsSettings.isEnabled ||
					this.state.menuSetMarkSettings.isEnabled ||
					this.state.sensorReplacesSettings.isEnabled
				) {
					this.setAllVisible(chart);
				}

				if (cnt.deferredDataPoints && cnt.deferredDataPoints.length > 0 && cnt.state.showReloadedValues) {
					this.addSeriesToChart(chart, 'Reloaded Values', PLOT_LINE_COLORS.RELOADED_VALUES);
				}

				if (cnt.sensorCalibrations && cnt.sensorCalibrations.length > 0 && cnt.state.showSensorCalibrationValues) {
					this.addSeriesToChart(chart, 'Calibration', PLOT_LINE_COLORS.CALIBRATION);
				}

				if (cnt.communication_times && cnt.communication_times.length > 0 && cnt.state.showMeasurementUploads) {
					this.addLineLegendItem('Communication times', PLOT_LINE_COLORS.COMMUNICATION_TIMES, chart);
				}

				if (cnt.state.startRunStopRunSettings.isEnabled && cnt.state.startRunStopRunSettings.showStartRunLegend) {
					this.addLineLegendItem(RUN_EVENTS.START_RUN[0], PLOT_LINE_COLORS.START_STOP_RUN, chart);
				}
				if (cnt.state.startRunStopRunSettings.isEnabled && cnt.state.startRunStopRunSettings.showStopRunLegend) {
					this.addLineLegendItem(RUN_EVENTS.STOP_RUN[0], PLOT_LINE_COLORS.START_STOP_RUN, chart);
				}

				if (cnt.state.alarmingOnAlarmingOffSettings.isEnabled && cnt.state.alarmingOnAlarmingOffSettings.showAlarmingOnLegend) {
					this.addLineLegendItem(ALARM_EVENTS.ALARMING_ON[0], PLOT_LINE_COLORS.ALARMING_ON_OFF, chart);
				}
				if (cnt.state.alarmingOnAlarmingOffSettings.isEnabled && cnt.state.alarmingOnAlarmingOffSettings.showAlarmingOffLegend) {
					this.addLineLegendItem(ALARM_EVENTS.ALARMING_OFF[0], PLOT_LINE_COLORS.ALARMING_ON_OFF, chart);
				}

				if (cnt.state.flightModeOnOffSettings.isEnabled && cnt.state.flightModeOnOffSettings.showFlightModeOnLegend) {
					this.addLineLegendItem(FLIGHT_MODE_EVENTS.FLIGHT_MODE_ON[0], PLOT_LINE_COLORS.FLIGHT_MODE_ON_OFF, chart);
				}
				if (cnt.state.flightModeOnOffSettings.isEnabled && cnt.state.flightModeOnOffSettings.showFlightModeOffLegend) {
					this.addLineLegendItem(FLIGHT_MODE_EVENTS.FLIGHT_MODE_OFF[0], PLOT_LINE_COLORS.FLIGHT_MODE_ON_OFF, chart);
				}

				if (cnt.state.logStatusesSettings.isEnabled && cnt.state.logStatusesSettings.showStartLegend) {
					this.addLineLegendItem(LOGGER_STATUS_EVENTS.START[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
				}
				if (cnt.state.logStatusesSettings.isEnabled && cnt.state.logStatusesSettings.showLogDelayedLegend) {
					this.addLineLegendItem(LOGGER_STATUS_EVENTS.LOG_DELAY[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
				}
				if (cnt.state.logStatusesSettings.isEnabled && cnt.state.logStatusesSettings.showLogTransitLegend) {
					this.addLineLegendItem(LOGGER_STATUS_EVENTS.LOG_TRANSIT[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
				}
				if (cnt.state.logStatusesSettings.isEnabled && cnt.state.logStatusesSettings.showLogPausedLegend) {
					this.addLineLegendItem(LOGGER_STATUS_EVENTS.LOG_PAUSED[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
				}
				if (cnt.state.logStatusesSettings.isEnabled && cnt.state.logStatusesSettings.showLogArrivedLegend) {
					this.addLineLegendItem(LOGGER_STATUS_EVENTS.LOG_ARRIVED[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
				}
				if (cnt.state.logStatusesSettings.isEnabled && cnt.state.logStatusesSettings.showLogStoppedLegend) {
					this.addLineLegendItem(LOGGER_STATUS_EVENTS.LOG_STOPPED[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
				}
				if (cnt.state.logStatusesSettings.isEnabled && cnt.state.logStatusesSettings.showCalibLegend) {
					this.addLineLegendItem(LOGGER_STATUS_EVENTS.CALIB[0], PLOT_LINE_COLORS.LOGGERS_STATUSES, chart);
				}

				if (cnt.state.sensorLimitAlarmsSettings.isEnabled && cnt.state.sensorLimitAlarmsSettings.showSensorUpperLimitAlarmLegend) {
					this.addLineLegendItem(SENSOR_LIMIT_ALARMS.UPPER_LIMIT_ALARM[0], PLOT_LINE_COLORS.SENSOR_LIMIT_ALARMS, chart);
				}
				if (cnt.state.sensorLimitAlarmsSettings.isEnabled && cnt.state.sensorLimitAlarmsSettings.showSensorLowerLimitAlarmLegend) {
					this.addLineLegendItem(SENSOR_LIMIT_ALARMS.LOWER_LIMIT_ALARM[0], PLOT_LINE_COLORS.SENSOR_LIMIT_ALARMS, chart);
				}

				if (cnt.state.sensorIssueAlarmsSettings.isEnabled && cnt.state.sensorIssueAlarmsSettings.showRadioConnectionWarningLegend) {
					this.addLineLegendItem(SENSOR_ISSUE_ALARMS.RADIO_CONNECTION_WARNING[0], PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS, chart);
				}
				if (cnt.state.sensorIssueAlarmsSettings.isEnabled && cnt.state.sensorIssueAlarmsSettings.showLostMeasurementAlarmLegend) {
					this.addLineLegendItem(SENSOR_ISSUE_ALARMS.LOST_MEASUREMENT_ALARM[0], PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS, chart);
				}
				if (cnt.state.sensorIssueAlarmsSettings.isEnabled && cnt.state.sensorIssueAlarmsSettings.showLowBatteryWarningLegend) {
					this.addLineLegendItem(SENSOR_ISSUE_ALARMS.LOW_BATTERY_WARNING[0], PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS, chart);
				}
				if (cnt.state.sensorIssueAlarmsSettings.isEnabled && cnt.state.sensorIssueAlarmsSettings.showMissingValueAlarmLegend) {
					this.addLineLegendItem(SENSOR_ISSUE_ALARMS.MISSING_VALUE_ALARM[0], PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS, chart);
				}
				if (
					cnt.state.sensorIssueAlarmsSettings.isEnabled &&
					cnt.state.sensorIssueAlarmsSettings.showMissingCommunicationWarningLegend
				) {
					this.addLineLegendItem(
						SENSOR_ISSUE_ALARMS.MISSING_COMMUNICATION_WARNING[0],
						PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
						chart
					);
				}
				if (cnt.state.sensorIssueAlarmsSettings.isEnabled && cnt.state.sensorIssueAlarmsSettings.showSensorFailureAlarmLegend) {
					this.addLineLegendItem(SENSOR_ISSUE_ALARMS.SENSOR_FAILURE_ALARM[0], PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS, chart);
				}

				if (cnt.state.menuSetMarkSettings.isEnabled && cnt.state.menuSetMarkSettings.showMenuSetMarkLegend) {
					this.addLineLegendItem(MENU_SET_MARK_EVENTS.MARK_COUNTER[0], PLOT_LINE_COLORS.MENU_SET_MARK, chart);
				}

				if (cnt.state.sensorReplacesSettings.isEnabled && cnt.state.sensorReplacesSettings.showSensorReplacesLegend) {
					this.addLineLegendItem(REPLACE_SENSOR_EVENTS.REPLACE_SENSOR[0], PLOT_LINE_COLORS.REPLACE_SENSOR, chart);
				}

				if (cnt.bypassedDataPoints && cnt.bypassedDataPoints.length > 0 && cnt.state.showBypassedValues) {
					this.addSeriesToChart(chart, 'Alarming inactive', PLOT_LINE_COLORS.ALARMING_INACTIVE);
				}

				if (cnt.occurrences && cnt.occurrences.length > 0 && cnt.state.showInTransit) {
					this.addLineLegendItem('In Transit', '#ebeeff', chart);
				}

				//deviation
				chart.xAxis[0].addPlotLine({
					color: '#f61214',
					width: 4,
					value: moment(cnt.props.deviationStart),
					className: 'deviation-start',
				});

				chart.xAxis[0].addPlotLine({
					color: '#b1b3b3',
					width: 4,
					value: moment(cnt.props.deviationEnd),
					className: 'deviation-end',
				});

				if (cnt.state.showMeasurementUploads && cnt.communication_times) {
					this.setMeasurementUploadsVisible(cnt, chart, true);
				}
			}

			cnt.chart = chart;
		}
	};

	render() {
		const chartOptions = ChartConfiguration(this.props);
		chartOptions.series = this.series;

		return (
			<>
				{this.props.measurements && this.props.measurements.length > 0 ? (
					<ChartLegend
						chartComponent={this}
						setUpdateFun={updateFun => {
							this.legendUpdateFun = updateFun;
						}}
					/>
				) : null}

				<div style={{marginBottom: '50px'}}>
					{this.props.filterActive && (
						<Button style={{float: 'right', marginRight: '20px'}} onClick={this.props.resetZoom}>
							<Trans i18nKey="global.resetZoom" />
						</Button>
					)}
				</div>
				<ReactHighcharts
					config={chartOptions}
					callback={chart => this.afterRender(this, chart)}
					isPureConfig={true}
					ref={this.props.forwardRef}
				/>
			</>
		);
	}
}

export default ChartComponent;
