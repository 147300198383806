import {FormStates} from '../../../../components/Wizard/Types';
import {AdditionalConfiguration, EcologProXGMetaDatasForm, SensorBasicsForm, WizardStateSummaryForm} from '../AddSensor/WizardState';
import {CreditCard} from '../../../../common/types';
import ModuleForm from '../Forms/ModuleForm';
import {WizardGetSensors} from './WizardGetSensors';
import {GetChannelTypes} from './GetChannelTypes';
import {DeviceUtils} from '../../../../common/util';
import {SmsAddonRequired} from './SmsAddonRequired';
import {mapCommunicationModeTemplateToAdditionalConfiguration} from '../../../../components/Forms/Mappers/CommunicationModes/CommunicationModeTemplateMapper';
import {CalculateConfigurationChecksum} from '../../../../common/services/WebserverServicePro/DeviceService';
import {IssueAlarmToIssueProfile} from '../Utils/IssueAlarmToIssueProfile';

export async function prepareConnectModuleRequest(
	sensorBasicsForm: SensorBasicsForm,
	moduleForm: ModuleForm,
	formStates: FormStates,
	summaryForm: WizardStateSummaryForm,
	offering: {actual_price: any},
	tiltEnabled: boolean,
	additionalConfiguration?: AdditionalConfiguration,
	metadatasForm?: EcologProXGMetaDatasForm,
	creditCard?: {credit_card: CreditCard; vouchers: any; redeem_prepaid: boolean}
) {
	let data = {
		module: moduleForm,
		sensors: WizardGetSensors(
			moduleForm.serial_number,
			sensorBasicsForm,
			formStates.limitAlarmForms,
			GetChannelTypes(sensorBasicsForm, DeviceUtils.GetDeviceFamily(moduleForm.serial_number)),
			metadatasForm
		),
		sensor_issue_alarm: IssueAlarmToIssueProfile(formStates.issueAlarmForm),
		payment_information: creditCard
			? {
					credit_card: creditCard,
					vouchers: summaryForm.vouchers,
					redeem_prepaid: creditCard.redeem_prepaid,
					logging_interval: sensorBasicsForm.logging_interval,
					sms_notifications: SmsAddonRequired(
						moduleForm.serial_number,
						sensorBasicsForm.selectedChannel?.selectedChannel,
						sensorBasicsForm.selectedChannel?.selectedValueIndex,
						formStates?.limitAlarmForms,
						formStates?.issueAlarmForm
					),
					offer_total: DeviceUtils.IsLiberoGx(moduleForm.serial_number) === true ? 0 : offering?.actual_price,
			  }
			: null,
		additional_configuration: null,
	};

	if (additionalConfiguration?.has_additional_configuration) {
		let addConfig = JSON.parse(JSON.stringify(additionalConfiguration.configuration_template_result));

		let useAlarming = formStates.limitAlarmForms?.some(l => !!l.alarmLimit);

		if (useAlarming) {
			addConfig['alarming_activation_visible'] = true;
			if (DeviceUtils.IsLiberoGx(moduleForm.serial_number)) {
				addConfig['alarming_pausing_allowed'] = true;
			}
		} else {
			addConfig['alarming_activation_visible'] = false;
			if (DeviceUtils.IsLiberoGx(moduleForm.serial_number)) {
				addConfig['alarming_pausing_allowed'] = false;
			}
		}

		addConfig = mapCommunicationModeTemplateToAdditionalConfiguration(
			sensorBasicsForm.communication_mode,
			additionalConfiguration.communication_mode_templates,
			addConfig
		);

		if (tiltEnabled && formStates.issueAlarmForm?.issueProfile?.tilt_warning) {
			const {tilt_warning_initial_position, tilt_warning_angle} = formStates.issueAlarmForm.issueProfile;
			if (
				tilt_warning_initial_position?.reference_x &&
				tilt_warning_initial_position?.reference_y &&
				tilt_warning_initial_position?.reference_z &&
				tilt_warning_angle
			) {
				addConfig = {
					...addConfig,
					tilt_detection: {
						enable: true,
						reference_x: tilt_warning_initial_position.reference_x,
						reference_y: tilt_warning_initial_position.reference_y,
						reference_z: tilt_warning_initial_position.reference_z,
						limit_angle: tilt_warning_angle,
					},
				};
			}
		}

		data = {...data, additional_configuration: addConfig};

		addConfig['config_id'] = await CalculateConfigurationChecksum(data);
	}
	return data;
}
