import React, {useContext} from 'react';
import {FormSummary, Row} from '../Summary/FormSummary';
import {AuthContext} from '../../../../common/context';
import {CONSTANTS} from '../../../../components/Shared/Constants/Constants';

export function TiltDetectionSummaryComponent(props: {
	readonly tiltDetection: {enabled: boolean; reference_x: number; reference_y: number; reference_z: number; limit_angle: number};
}): React.JSX.Element {
	const authContext = useContext(AuthContext);

	if (!props.tiltDetection?.enabled && !authContext.Organization.Config?.global?.tiltEnabled) {
		return null;
	}

	const rows: Row[] = [
		{key: 'sensorWizard.tiltDetection.enabled', value: 'True'},
		{
			key: 'sensorWizard.tiltDetection.limit_angle',
			value: props.tiltDetection?.limit_angle ? `${props.tiltDetection.limit_angle}°` : CONSTANTS.NOT_AVAILABLE,
		},
		{key: 'sensorWizard.tiltDetection.ref_x', value: (props.tiltDetection?.reference_x ?? 0.0).toString()},
		{key: 'sensorWizard.tiltDetection.ref_y', value: (props.tiltDetection?.reference_y ?? 0.0).toString()},
		{key: 'sensorWizard.tiltDetection.ref_z', value: (props.tiltDetection?.reference_z ?? 1.0).toString()},
	];

	return <FormSummary title={'sensorWizard.tiltDetection.title'} rows={rows} />;
}
