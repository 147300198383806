import {IssueAlarmFormState} from '../Forms/SensorIssueForm/IssueAlarmFormState';

export function IssueAlarmToIssueProfile(issueAlarmFormState: IssueAlarmFormState): any {
	return {
		issue_alarm_creation_type: issueAlarmFormState?.issueProfile?.id ?? null,
		use_issue_alarms: !!issueAlarmFormState?.issueProfile,
		limit_issue_name: issueAlarmFormState?.issueProfile?.name ?? null,

		missing_value_alarm: false, //Not used anymore
		missing_value_alarm_delay: 1, //Not used aynmore
		lost_measurement_alarm: issueAlarmFormState?.issueProfile?.lost_measurement_alarm,
		missing_communication_warning: issueAlarmFormState?.issueProfile?.missing_communication_warning,
		missing_communication_warning_enum: issueAlarmFormState?.issueProfile?.missing_communication_warning_enum,
		light_warning: false,
		tilt_warning: issueAlarmFormState?.issueProfile?.tilt_warning,
		sensor_failure_alarm: issueAlarmFormState?.issueProfile?.sensor_failure_alarm,
		sensor_failure_alarm_delay: issueAlarmFormState?.issueProfile?.sensor_failure_alarm_delay,
		radio_connection_warning: false, //Not used anymore https://elproag.atlassian.net/browse/TH-460
		radio_connection_warning_delay: 1, //Not used anymore https://elproag.atlassian.net/browse/TH-460
		radio_connection_warning_threshold: -90, //Not used anymore https://elproag.atlassian.net/browse/TH-460
		low_battery_warning: issueAlarmFormState?.issueProfile?.low_battery_warning,

		email_notification: issueAlarmFormState?.recipients.email.enabled ?? false,
		email_users: issueAlarmFormState?.recipients.email.recipients.map(u => u.Id) ?? [],
		sms_notification: issueAlarmFormState?.recipients.sms.enabled ?? false,
		sms_users: issueAlarmFormState?.recipients.sms.recipients.map(u => u.Id) ?? [],
	};
}
