import React from 'react';
import {Trans} from 'react-i18next';
import {CONSTANTS} from '../../../../../../../components/Shared/Constants/Constants';
import {IssueProfile} from '../../../SensorIssueForm/IssueAlarmFormState';
import {TILT_WARNING_INITIAL_POSITIONS} from '../../../../../../../components/Shared/Constants/TiltWarning/TiltWarning';

interface IssueAlarmsTiltWarningProps {
	sensorIssuesForm: IssueProfile;
}

export default function IssueAlarmsTiltWarningComponent(props: Readonly<IssueAlarmsTiltWarningProps>) {
	return (
		<>
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.tiltWarning'} />
				</td>
				<td>
					{(() => {
						if (props.sensorIssuesForm == null) {
							return CONSTANTS.NOT_AVAILABLE;
						}
						return props.sensorIssuesForm.tilt_warning ? CONSTANTS.YES : CONSTANTS.NO;
					})()}
				</td>
			</tr>
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.tiltWarningInitialPosition'} />
				</td>
				<td>
					{(() => {
						const {tilt_warning_initial_position: pos} = props.sensorIssuesForm || {};
						if (!pos?.reference_x || !pos?.reference_y || !pos?.reference_z) {
							return CONSTANTS.NOT_AVAILABLE;
						}

						const selectedPosition = Object.values(TILT_WARNING_INITIAL_POSITIONS).find(
							v => v.reference_x === pos.reference_x && v.reference_y === pos.reference_y && v.reference_z === pos.reference_z
						);

						return selectedPosition?.name || CONSTANTS.NOT_AVAILABLE;
					})()}
				</td>
			</tr>
			<tr>
				<td>
					<Trans i18nKey={'sensorWizard.summary.tiltWarningLimitAngle'} />
				</td>
				<td>
					{(() => {
						if (props.sensorIssuesForm?.tilt_warning_angle == null) {
							return CONSTANTS.NOT_AVAILABLE;
						}
						return props.sensorIssuesForm.tilt_warning_angle;
					})()}
				</td>
			</tr>
		</>
	);
}
